<template>
  <div>
    <b-row id="linhaIndicadores">
      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Contratos consolidados"
            :total="chartContratosConsolidados"
            :series="hardcodedSeries([10, 12, 14])"
          />
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title=" Valor total de remessas"
            :total="chartTotalRemessas"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#CDDC39"
          />
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Valor histórico de lucro líquido"
            :total="chartLucroLiquido"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#689F38"
          />
        </b-card>
      </b-col>

      <b-col sm="12" md="3">
        <b-card>
          <CardIndicador
            id="margemEfetivo"
            :isLoading="isLoadingIndicadores"
            title="Valor histórico total de juros"
            :total="chartTotalJuros"
            :series="hardcodedSeries([10, 12, 14])"
            barColor="#E91E63"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { BCard, BCol } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import VueApexCharts from 'vue-apexcharts'

  var cardIndicadorMinHeight = 0

  const CardIndicador = {
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
      },
      total: {
        type: String,
        default: '0',
      },
      series: {
        type: Array,
        default: null,
      },
      barColor: {
        type: String,
        default: '#2196F3',
      },
    },
    components: {
      VueApexCharts,
    },
    data() {
      return {
        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              startingShape: 'rounded',
              endingShape: 'rounded',
              borderRadius: 5,
              borderRadiusApplication: 'around',
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [this.barColor],
          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            enabled: false,
            x: { show: false },
            y: { show: false },
          },
        },
      }
    },
    mounted() {
      this.checkTitleHeight()
    },
    methods: {
      boldRowClass(item, type) {
        return item.negrito ? 'font-weight-bold' : ''
      },
      checkTitleHeight() {
        if (this.$refs.title.offsetHeight > cardIndicadorMinHeight) {
          cardIndicadorMinHeight = this.$refs.title.offsetHeight
        }
      },
    },
    render() {
      return (
        <b-card no-body>
          <b-card-body class="pb-0">
            <strong class="d-inline-block" id="title" ref="title" style={`min-height:${cardIndicadorMinHeight}px`}>
              {this.title}
            </strong>
            {this.isLoading && <b-spinner big class="d-block mt-1 mb-2" />}
            {!this.isLoading && (
              <div style="position: relative;">
                <h1 class="font-weight-bolder mb-0 py-1" style="z-index:2;">
                  {this.total}
                </h1>
                <div style="position: absolute; right: 0; bottom:0; width: 70px; opacity: .9">
                  <vue-apex-charts height="50" options={this.chartOptions} series={this.series} />
                </div>
              </div>
            )}
          </b-card-body>
        </b-card>
      )
    },
  }

  export default {
    components: {
      BCard,
      BCol,
      ToastificationContent,
      VueApexCharts,
      CardIndicador,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        // Charts
        chartContratosConsolidados: '0',
        chartTotalRemessas: '0',
        chartLucroLiquido: '0',
        chartTotalJuros: '0',
        isLoadingIndicadores: false,
      }
    },
    mounted() {
      this.carregaIndicadores()
    },
    methods: {
      carregaIndicadores() {
        this.isLoadingIndicadores = true
        useJwt
          .post('financeiro/GetIndicadoresContratos', {
            institutoId: this.userData.institutoSelecionado,
          })
          .then((result) => {
            this.chartContratosConsolidados = result.data.chartContratosConsolidados
            this.chartTotalRemessas = result.data.chartTotalRemessas
            this.chartLucroLiquido = result.data.chartLucroLiquido
            this.chartTotalJuros = result.data.chartTotalJuros
            this.isLoadingIndicadores = false
          })
          .catch((error) => {
            console.error(error)
            this.isLoadingIndicadores = false
            this.$message.error('Erro ao consultar indicadores!')
          })
      },
      msgError(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
      hardcodedSeries(data) {
        return [
          {
            name: '',
            data: data.map((d) => ({ x: '', y: d })),
          },
        ]
      },
    },
  }
</script>

<style></style>
