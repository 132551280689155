<template>
  <div>
    <b-tabs>
      <b-tab v-if="acesso.RelatorioContratoContratos">
        <template #title>
          <feather-icon icon="ShoppingBagIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Contratos</span>
        </template>
        <relatorioContratos />
      </b-tab>

      <b-tab v-if="acesso.RelatorioContratoSaldoDevedor">
        <template #title>
          <feather-icon icon="ShoppingBagIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Saldo Devedor</span>
        </template>
        <relatorioSaldoDevedor />
      </b-tab>

      <b-tab v-if="acesso.RelatorioContratoSuspensaoJudicial">
        <template #title>
          <feather-icon icon="SlashIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Suspensão Judicial</span>
        </template>
        <relatorioSuspensaoJudicial />
      </b-tab>
    </b-tabs>

    <b-card class="text-center" v-if="todosFalsos">
      <b-card-body>
        <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
        <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
        <b-card-text class="mb-1">Parece que seu usuário não tem permissão para acessar abas deste módulo.</b-card-text>
        <b-card-text class="mb-1"
          >Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.</b-card-text
        >
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
  import relatorioContratos from './relatorioContratos.vue'
  import relatorioSaldoDevedor from './relatorioSaldoDevedor.vue'
  import relatorioSuspensaoJudicial from './relatorioSuspensaoJudicial.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      relatorioContratos,
      relatorioSaldoDevedor,
      relatorioSuspensaoJudicial,
      ToastificationContent,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        acesso: {
          RelatorioContratoContratos: false,
          RelatorioContratoSaldoDevedor: false,
          RelatorioContratoSuspensaoJudicial: false,
        },
      }
    },
    computed: {
      todosFalsos() {
        return Object.values(this.acesso).every((valor) => valor === false)
      },
    },

    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.RelatorioContratoContratos = true
        this.acesso.RelatorioContratoSaldoDevedor = true
        this.acesso.RelatorioContratoSuspensaoJudicial = true
      } else {
        this.acesso.RelatorioContratoContratos = this.userData.GrupoAcesso.RelatorioContratoContratos
        this.acesso.RelatorioContratoSaldoDevedor = this.userData.GrupoAcesso.RelatorioContratoSaldoDevedor
        this.acesso.RelatorioContratoSuspensaoJudicial = this.userData.GrupoAcesso.RelatorioContratoSuspensaoJudicial
      }
    },
  }
</script>
