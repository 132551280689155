<template>
  <div>
    <b-card>
      <h2>Filtros</h2>
      <validation-observer ref="formPesquisar">
        <b-form>
          <b-row>
            <b-col md="">
              <b-form-group label="Pesquisar Por:" label-for="pesquisarPor">
                <b-form-select id="pesquisarPor" v-model="pesquisar.tipoOpcoes" :options="tipoOpcoes" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Descrição" label-for="descricao">
                <b-form-input
                  id="descricao"
                  :placeholder="'Nº Contrato'"
                  v-model="pesquisar.descricao"
                  v-on:keydown.enter="carregarGrid"
                />
              </b-form-group>
            </b-col>
            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 25px; width: 160px"
                @click="carregarGrid()"
              >
                {{ !isBusy ? 'Pesquisar' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
            <b-col md="auto">
              <template>
                <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-top: 24px; width: 160px"
                    >
                      Exportar
                    </b-button>
                  </template>
                  <b-dropdown-item href="#" @click="exportTable('pdf')">
                    <feather-icon icon="FileTextIcon" size="16" />
                    <span class="align-middle ml-50">PDF</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card v-if="this.items != null ? this.items.length > 0 : false">
      <div class="mb-1">
        <span v-show="items.length > 0"
          >Total de parcela(s):
          <b-badge variant="primary" pill>
            {{ totals.rows }}
          </b-badge> </span
        ><br />
        <span v-show="items.length > 0"
          >Saldo devedor:
          <b-badge variant="primary" pill>
            {{ totals.devedor }}
          </b-badge>
        </span>
      </div>
      <b-table
        v-show="items.length > 0"
        striped
        responsive
        id="tableRelatoriosContratos"
        ref="tableRelatoriosContratos"
        class="position-relative"
        :per-page="pesquisar.perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :busy="isBusy"
        :current-page="pesquisar.currentPage"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        <template #cell(statusFinanceiro)="finanrow">
          <b-badge pill :variant="status[0][finanrow.item.statusFinanceiro]">
            {{ finanrow.item.statusFinanceiro }}
          </b-badge>
        </template>
      </b-table>
      <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          style="align-items: center"
          label="Por Página"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <b-pagination
          v-model="pesquisar.currentPage"
          :total-rows="totals.rows"
          :per-page="pesquisar.perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>

      <div>
        <relatorioFinanceiroSaldoDevedor ref="exportarPDF" :parametro="parametroPDF" />
      </div>
    </b-card>
  </div>
</template>

<script>
  import {
    BCard,
    BPagination,
    BTab,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BTable,
    BSpinner,
    BBadge,
    BRow,
    BCol,
    BButton,
    BForm,
    BDropdown,
    BDropdownItem,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import moment from 'moment'
  import Cleave from 'vue-cleave-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Ripple from 'vue-ripple-directive'
  import { formatarData, formatarDataWTime, formatarValorReais, formatarValorPorcentagem } from '@/libs/utils'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import VueHtml2pdf from 'vue-html2pdf'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import relatorioFinanceiroSaldoDevedor from '@/views/pages/relatorio/relatorioContratos/components/relatorioFinanceiroSaldoDevedor.vue'
  import relatorioContratosIndicadores from './relatorioContratosIndicadores.vue'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import { asDefault } from './components/exportTableContratos'
  import { required, dataFutura, dataPassada } from '@validations'

  export default {
    components: {
      relatorioFinanceiroSaldoDevedor,
      relatorioContratosIndicadores,
      BCard,
      BPagination,
      dataFutura,
      required,
      dataPassada,
      BTab,
      BCardBody,
      BFormGroup,
      BFormSelect,
      BTable,
      BSpinner,
      BBadge,
      BRow,
      BCol,
      BButton,
      BForm,
      Cleave,
      ToastificationContent,
      ValidationProvider,
      ValidationObserver,
      BDropdown,
      BDropdownItem,
      VueHtml2pdf,
      BCardCode,
      formatarData,
      formatarDataWTime,
      generateAndDownloadPdf,
      formatarValorReais,
      formatarValorPorcentagem,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarData,
        formatarDataWTime,
        isBusy: false,

        //Search
        tipoOpcoes: [{ value: 2, text: 'Nº Contrato' }],
        datePicker: {
          dataInicial: '',
          dataFinal: '',
        },
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
        pesquisar: {
          tipoOpcoes: 2,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          dataInicial: '',
          dataFinal: '',
          statusOpcoes: '',
        },
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Consolidado', text: 'Consolidado' },
          { value: 'Quitado', text: 'Quitado' },
          { value: 'Cancelado', text: 'Cancelado' },
          { value: 'Segurado Exonerado', text: 'Segurado Exonerado' },
          { value: 'Segurado Falecido', text: 'Segurado Falecido' },
        ],

        //table
        pageOptions: [10, 25, 50, 100],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        totals: {
          rows: 0,
          devedor: 0,
        },
        items: [],
        status: [
          {
            Consolidado: 'success',
            Pendente: 'warning',
          },
        ],
        fields: [
          { key: 'parcela', label: 'Parcela', sortable: true },
          {
            key: 'vencimento',
            label: 'Data de Vencimento',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          { key: 'valor', label: 'Valor da Parcela', sortable: true, formatter: (value) => formatarValorReais(value) },

          { key: 'statusGRPE', label: 'Situação do Valor Pago', sortable: true },
        ],

        // PDF
        parametroPDF: {
          dados: [],
          colunas: [],
          cabecalhoFilds: [],
          cabecalho: [],
          institutoNome: '',
        },
        fieldsPDF: [
          { key: 'parcela', label: 'Parcela' },
          {
            key: 'vencimento',
            label: 'Data Vencimento',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          { key: 'valor', label: 'Valor Parcela', formatter: (value) => formatarValorReais(value) },
          {
            key: 'pagamento',
            label: 'Data Pagamento.',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          { key: 'statusGRPE', label: 'Situação' },
          { key: 'descontoRealizado', label: 'Desconto', formatter: (value) => formatarValorReais(value) },
        ],
        fieldsCABECALHO: [
          { key: 'valorDoEmprestimo', label: 'Valor do Empréstimo', formatter: (value) => formatarValorReais(value) },

          { key: 'valorSolicitado', label: 'ValorSolicitado', formatter: (value) => formatarValorReais(value) },

          { key: 'taxaJuros', label: 'Taxa ao Mês', formatter: (value) => formatarValorPorcentagem(value) },
          { key: 'taxaAno', label: 'Taxa ao Ano', formatter: (value) => formatarValorPorcentagem(value) },
          { key: 'taxaEfetivaIOF', label: 'IOF', formatter: (value) => formatarValorPorcentagem(value) },
          {
            key: 'dataBase',
            label: 'Data Base',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          { key: 'saldoDevedor', label: 'Saldo Devedor', formatter: (value) => formatarValorReais(value) },
          { key: 'valorPago', label: 'Valor Pago', formatter: (value) => formatarValorReais(value) },
        ],
        fieldsTOMADOR: [
          { key: 'nome', label: 'Nome' },
          { key: 'codigo', label: 'Nº Contrato' },
          { key: 'matricula', label: 'Matricula' },
        ],
      }
    },
    methods: {
      async fetchContratos(aux) {
        const res = await useJwt.pesquisar('financeiro/GetRelatorioContratoSaldoDevedor', aux).catch((error) => {
          this.isBusy = false
          console.error(error)
        })
        return res.data
      },
      carregarGrid() {
        this.$refs.formPesquisar.validate().then(async (success) => {
          if (success) {
            if (this.pesquisar.descricao) {
              this.isBusy = true
              const data = await this.fetchContratos({
                ...this.pesquisar,
                institutoSelecionado: this.userData.institutoSelecionado,
                statusOpcoes: this.pesquisar.statusOpcoes ?? null,
                descricao: this.pesquisar.descricao ?? null,
              })
              if (this.isEmpety(data)) {
                this.$message.error('Não há dados para exibir')
                this.items = null
                this.isBusy = false
                return
              } else {
                this.items = data.parcelas

                this.totals = {
                  rows: this.items?.length ?? 0,
                  devedor: formatarValorReais(data?.saldoDevedor),
                }

                this.isBusy = false
              }
            } else {
              this.$message.error('Preencha um Número de Contrato')
              this.items = null
              this.isBusy = false
            }
          }
        })
      },
      isEmpety(obj) {
        return Object.keys(obj).length === 0
      },
      async exportTable(mode) {
        const data = await this.fetchContratos({
          ...this.pesquisar,
          institutoSelecionado: this.userData.institutoSelecionado,
          statusOpcoes: this.pesquisar.statusOpcoes ?? null,
          descricao: this.pesquisar.descricao ?? null,
          export: true,
        })

        const {
          valorSolicitado,
          valorDoEmprestimo,
          saldoDevedor,
          taxaEfetivaIOF,
          dataBase,
          taxaJuros,
          taxaAno,
          valorPago,
          matricula,
          nome,
          codigo,
        } = data
        const arrayCampos = [
          {
            valorSolicitado,
            valorDoEmprestimo,
            saldoDevedor,
            taxaEfetivaIOF,
            dataBase,
            taxaJuros,
            taxaAno,
            valorPago,
          },
        ]

        const arrayTomador = [
          {
            matricula,
            nome,
            codigo,
          },
        ]

        if (mode === 'pdf') {
          this.parametroPDF = {
            dados: data.parcelas,
            rows: data.parcelas.length,
            colunas: this.fieldsPDF,
            institutoNome: this.userData.Instituto.nome,
            cabecalhoFilds: this.fieldsCABECALHO,
            cabecalho: arrayCampos,
            dadosTomador: arrayTomador,
            cabecalhoTomador: this.fieldsTOMADOR,
          }
          let self = this
          setTimeout(function () {
            self.exportToPDF(self.parametroPDF)
          }, 3000)
        }
        // else if (mode === 'excel') {
        //   this.exportExcel(data.dados)
        // }
      },
      exportToPDF(parametroPDF) {
        if (!parametroPDF.rows > 0) {
          this.$message.error('Não há dados para exportar')
          return
        }
        if (this.$refs.tableRelatoriosContratos) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Período: ${this.pesquisar.dataInicial} a ${this.pesquisar.dataFinal}`
          let nomeRelatorio = `Relatório de Saldo Devedor`
          let informacoesInstituto = `
                    ${this.userData.Instituto.logradouro},
                    ${this.userData.Instituto.numero},
                    ${this.userData.Instituto.bairro},
                    ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                    E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                  `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            null,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportExcel(items) {
        try {
          this.$message.warning('Aguarde, estamos gerando o relatório')
          asDefault().export(items)
        } catch (error) {
          this.$message.error('Ocorreu um erro ao gerar o relatório', 'error')
          console.error(error)
        }
      },
    },
    watch: {
      dataInicialDatePicker(newVal) {
        this.pesquisar.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker(newVal) {
        this.pesquisar.dataFinal = formatarData(this.dataFinalDatePicker)
      },
    },
  }
</script>

<style></style>
import { array } from 'fp-ts/lib/Array'
